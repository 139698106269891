<template>
    <modal name="notes" ref="note" width="48%" height="62%" class="rounded-full" @closed="onClosed()">
        <div class="flex flex-col justify-between min-h-full h-full bg-white">
            <header class="modal-header min-h-16 2xl:min-h-20 flex items-center justify-between px-10 border-b border-0 border-solid border-gray-200">
                <span class="font-semibold text-2xl tracking-wide text-white-text flex items-center justify-center gap-3"
                    ><span>
                        <NotesIcon />
                    </span>
                    Note</span
                >
                <!--Handle close svg-->
                <CloseIcon class="cursor-pointer w-6 h-6" @click="handleCloseModal()" />
            </header>

            <div class="container flex-grow w-full max-w-full overflow-y-auto scroll-bar">
                <!-- form ui -->
                <div class="form-wrapper h-full">
                    <div class="form-container px-10 flex flex-col gap-5 py-5 h-full">
                        <div class="note-text-area flex-1">
                            <textarea class="text-area-control" placeholder="Type your text here" :value="notes.note" @input="onNotesChange"> </textarea>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="flex items-end justify-between mb-0 mr-3">
                <div class="footer-left-actions h-14 border-none py-4 px-9 cursor-pointer rounded text-sm flex items-center gap-6 bg-primary text-white-text">
                    <label for="notes_attachments" class="cursor-pointer relative w-8 h-8 grid place-content-center">
                        <EncloseIcon />
                        <span v-if="notes && notes.attachments" class="absolute -top-1 right-0 bg-white text-black rounded-full w-3 h-3 grid place-content-center">{{ notes.attachments.length }}</span>
                    </label>

                    <input @change="fetchFileName($event, 'notes_attachments')" id="notes_attachments" type="file" name="notes_attachments" multiple="multiple" class="hidden" />
                    <span>
                        <DelIcon @click="onClosed()" />
                    </span>
                </div>
                <div class="pb-3">
                    <!-- <neo-button text="Save Note" type="submit" :disabled="!notes.type || !notes.note" class="btn-primary w-32 text-sm h-9 border-none font-semibold rounded-xl mr-10 px-6" @click="method('add-note')" @keyup.enter="$emit('submit')"> </neo-button> -->
                    <button @click="$emit('method', 'add-note')" :disabled="!notes.note" class="btn normal-case leading-3 min-h-0 py-2.5 gap-2 flex items-center no-animation focus:bg-blue-700 btn-primary w-32 text-sm h-9 border-none font-semibold rounded-xl mr-10 px-6 btn-submit" :class="notes.note ? 'bg-blue-600' : 'bg-gray-300'"><!----><span class="capitalize font-bold tracking-wide">Save Note</span></button>
                </div>
            </footer>
        </div>
    </modal>
</template>

<script>
import NotesIcon from "@shared/assets/wnotes.svg";
import CloseIcon from "@shared/assets/close-icon.svg";
import EncloseIcon from "@shared/assets/enclosure-icon-white.svg";
import DelIcon from "@shared/assets/delete-icon-white.svg";

export default {
    name: "Notes",
    components: {
        NotesIcon,
        CloseIcon,
        EncloseIcon,
        DelIcon,
    },
    props: {
        notes: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        handleCloseModal() {
            this.$modal.hide("notes");
        },
        onClosed() {
            this.$emit("handleResetCaseNotes");
        },
        onNotesChange(event) {
            console.log(event, "EVENt");
            this.$emit("onNotesChange", event);
        },
        fetchFileName($event, type) {
            this.$emit("fetchFileName", { event: $event, type });
        },
    },
};
</script>

<style lang="scss" scoped>
.footer-left-actions {
    @apply bg-primary;
    border-top-right-radius: 24px;
}
</style>
